<template>
  <div class="uk-container">
    <notifications group="public" position="bottom center"  :style="{marginBottom:'2px'}"/>
    <v-row class="uk-container">
      <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
        <base-arrow-back-icon @click="$router.go(-1)">{{
          rightArrowIcon
        }}</base-arrow-back-icon>
        <span v-if="isUpdatePage"> تعديل وحدة </span>
        <span v-if="isNewPage">اضافة وحدة جديدة </span>
        <span v-if="isPreviewPage">عرض الوحدة </span>
      </h1>
    </v-row>
    <v-row class="uk-container uk-margin-large-top">
      <v-form ref="form" lazy-validation>
        <v-container class="grey lighten-5">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="data.title"
                class="pa-2"
                label="العنوان"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'العنوان مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="data.order"
                class="pa-2"
                type="number"
                label="الترتيب"
                :readonly="isPreviewPage"
                v-if="!isTranslationPage"
                :rules="[(v) => !!v || 'الترتيب مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="data.description"
                class="pa-2"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'الشرح مطلوب']"
                label="الشرح"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <div class="text-center" v-if="!isPreviewPage">
          <v-btn color="success" class="mr-4" @click="submit">
            <span v-if="isUpdatePage"> تعديل</span>
            <span v-else> إدخال</span>
          </v-btn>
        </div>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    BaseArrowBackIcon
  },
  data() {
    return {
      data: {
        title: "",
        description: "",
        order: "",
      },
    };
  },
  computed: {
    isTranslationPage() {
      return /add-translation\/[0-9]/.test(this.$route.path);
    },
    isUpdatePage() {
      return /update\/[0-9]/.test(this.$route.path);
    },
    isNewPage() {
      return /new/.test(this.$route.path);
    },
    isPreviewPage() {
      return /show\/[0-9]/.test(this.$route.path);
    },
    ...mapGetters({
      module: "getModule",
    }),
  },
  watch: {
    module: function () {
      this.data.title = this.module.title;
      this.data.description = this.module.description;
      this.data.order = this.module.order;
    },
  },
  mounted() {
    if (!this.isNewPage) {
      this.fetchSingleModule(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions([
      "addNewModuleInstructor",
      "addModuleTranslation",
      "fetchSingleModule",
      "updateModuleInstructor",
    ]),
    submit() {
      if (!this.$refs.form.validate()) return;
      if (this.isTranslationPage) {
        let data = {
          title: this.data.title,
          description: this.data.description,
          module_id: this.$route.params.id,
        };
        this.addModuleTranslation(data).then(() => {
          this.$router.push({
            name: "modulesIndex",
            params: { id: this.$route.params.id },
          });
        });
      } else if (this.isUpdatePage) {
        let data = {
          data: this.data,
          moduleId: this.$route.params.id,
        };
        this.updateModuleInstructor(data).then((res) => {
          if(res?.data?.status)
            if (res.data.status === 'success') {
          this.$router.push({
            name: "modulesIndex",
            params: { id: this.$route.params.id },
            query: { fromWhere: 'addEdit'}
          });
            }
        });
      } else {
        let data = {
          ...this.data,
          language: "en",
          course_id: this.$route.params.id,
        };
        this.addNewModuleInstructor(data).then((res) => {
          if(res?.data?.status)
            if (res.data.status === 'Success') {
            this.$router.push({
              name: "modulesIndex",
              params: { id: this.$route.params.id },
              query: { fromWhere: 'addEdit'}
            });
          }
        });
      }
    },
  },
};
</script>